:root {
  --core-fg-primary: #302a27;
  --core-fg-terciary: #92857b;
  --core-border: #e5dfd9;
}

.PaidInvoicesExportModal__Divider {
  margin: 30px -40px 20px;
  border-top: 1px solid var(--core-border);
}

.PaidInvoicesExportModal__Heading {
  color: var(--core-fg-primary);
  font-weight: 700;
  line-height: "110%";
  letter-spacing: "-0.01875rem";
}

.PaidInvoicesExportModal__InputLabel {
  color: var(--core-fg-primary);
  font-weight: 500;
}

.PaidInvoicesExportModal__Select {
  border: 1px solid var(--core-border);
  color: var(--core-fg-primary);
}

:root {
  --core-border: #e5dfd9;
  --fg-color: #92857b;
  --fg-color-secondary: #302a27;
  --fg-color-tertiary: #8e857d;
  --leaf-color: #3baa5a;
  --leaf-color-bg: #eff8f2;
  --chart-bars-active: #25ce78;
  --chart-bars-inactive: #d4f4e0;
  --chart-lines-green1: #00bc67;
  --chart-lines-green2: #00705a;
  --chart-lines-green3: #7bdd41;
}

.ChartBarContainer {
  position: relative;
}

.ChartBar {
  fill: var(--chart-bars-active);
  transition: fill 0.2s ease-in;
}

.ChartBarContainer:has(.ChartBar:hover) .ChartBar {
  fill: var(--chart-bars-inactive);
}

.ChartBarContainer:has(.ChartBar:hover) .ChartBar:hover {
  fill: var(--chart-bars-active);
}

.ChartBarGrid {
  & :first-child {
    stroke-dasharray: none;
    stroke: var(--core-border);
  }

  & :not(:first-child) {
    stroke: var(--core-border);
  }
}

.ChartBarXAxisLine {
  stroke: var(--core-border);
}

.ChartBarYAxisLine__TickLabel {
  font-size: 1rem;
  stroke-width: 0;
  fill: var(--fg-color);
  width: 30px;
}

.ActiveRiders__Stats {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.ActiveRiders__Stats > div {
  border-top: 1px solid var(--core-border);
}
.ActiveRiders__Stats > div:not(:last-child) {
  border-right: 1px solid var(--core-border);
}

.Estimateds_List {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.Estimateds_List > div:not(:last-child) {
  border-right: 1px solid var(--core-border);
}
.Estimateds_List__Item__Icon {
  background: var(--leaf-color-bg);
  height: 60px;
  width: 60px;
  border-radius: 16px;
  color: var(--leaf-color);
}
.Estimateds_List__Item__Icon > svg {
  height: 24px;
  width: 24px;
}

.PortalActivity {
  display: grid;
  grid-template-columns: 200px 1fr;
}
.PortalActivity_List {
  border-right: 1px solid var(--core-border);
}
.PortalActivity_List > div:not(:last-child) {
  border-bottom: 1px solid var(--core-border);
}

.ActivityXYChart__AxisLine {
  stroke: var(--core-border);
  stroke-width: 1;
}

@media print {
  .InsightsContainer {
    margin: 0;
    width: 100%;
    min-width: 0;
    max-width: 100%;
    padding: 0;
  }
}
